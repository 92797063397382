export default {
  path: '/settlementReport',
  name: 'settlementReport',
  meta: { title: '结算报表' },
  redirect: '/',
  auth: 'reportForms',
  component: () => import('@/views/settlementReport/index.vue'),
  children: [
    {
      path: '/settlementReport/householdSummary',
      name: 'householdSummary',
      meta: { title: '分级分户明细表', swiper: true },
      auth: 'summaryOfHouseHold',
      svgName: 'buy',
      component: () => import('@/views/reportForms/householdSummary'),
    },
    {
      path: '/settlementReport/inventoryOfHousehold',
      name: 'inventoryOfHousehold',
      meta: { title: '分户清册表', swiper: true },
      auth: 'inventoryOfHouseHold',
      svgName: 'buy',
      component: () => import('@/views/reportForms/inventoryOfHousehold'),
    },
    {
      path: '/settlementReport/obversionAndGrade',
      name: 'obversionAndGrade',
      meta: { title: '每日折干分级汇总' },
      auth: 'obversionAndGrade',
      svgName: 'buy',
      component: () => import('@/views/reportForms/obversionAndGrade'),
    },
    {
      path: '/settlementReport/dailyDryingSummaryTable',
      name: 'dailyDryingSummaryTable',
      meta: { title: '日烘干汇总表' },
      auth: 'gramManagementOfficeDryingFee',
      svgName: 'buy',
      component: () => import('@/views/reportForms/dailyDryingSummaryTable'),
    },
    {
      path: '/settlementReport/householdSettlementList',
      name: 'householdSettlementList',
      meta: { title: '送农发行明细表', swiper: true },
      auth: 'householdSettlementList',
      svgName: 'settlement',
      component: () => import('@/views/reportForms/householdSettlementList'),
    },
    {
      path: '/settlementReport/agriculturalDevelopmentBankTotal',
      name: 'agriculturalDevelopmentBankTotal',
      meta: { title: '送农发行统计表' },
      auth: 'agriculturalDevelopmentBankTotal',
      svgName: 'settlement',
      component: () => import('@/views/reportForms/agriculturalDevelopmentBankTotal'),
    },

    {
      path: '/settlementReport/dryingFeeStatisticsTable',
      name: 'dryingFeeStatisticsTable',
      meta: { title: '烘干费统计表', swiper: true },
      auth: 'dryingFeeForms',
      svgName: 'buy',
      component: () => import('@/views/reportForms/dryingFeeStatisticsTable'),
    },
    {
      path: '/settlementReport/freightStatisticsTable',
      name: 'freightStatisticsTable',
      meta: { title: '运费统计表', swiper: true },
      auth: 'freightForms',
      svgName: 'buy',
      component: () => import('@/views/reportForms/freightStatisticsTable'),
    },
    {
      path: '/settlementReport/transferInventory',
      name: 'transferInventory',
      meta: { title: '转账清单' },
      auth: 'listTransferList',
      svgName: 'settlement',
      component: () => import('@/views/reportForms/transferInventory/list'),
    },
    {
      path: '/settlementReport/transferInventoryAdd',
      name: 'transferInventoryAdd',
      meta: { title: '转账清单新增' },
      auth: 'createTransferList',
      hide: true,
      component: () => import('@/views/reportForms/transferInventory/add'),
    },
    {
      path: '/settlementReport/transferInventoryEdit/:id',
      name: 'transferInventoryEdit',
      meta: { title: '转账清单编辑' },
      auth: 'updateTransferList',
      hide: true,
      component: () => import('@/views/reportForms/transferInventory/edit'),
    },
    {
      path: '/settlementReport/transferInventoryDetail/:id',
      name: 'transferInventoryDetail',
      meta: { title: '转账清单详情' },
      auth: 'detailTransferList',
      hide: true,
      component: () => import('@/views/reportForms/transferInventory/detail'),
    },
  ],
}
